import React from 'react';
import { graphql } from 'gatsby';

import BlockContent from '@components/BlockContent';
import ContentNarrow from '@components/ContentNarrow';
import Layout from '@components/Layout';

import { moduleSettings } from '@utils';
import { Container } from '@elements';
import Pagination from '../components/Pagination';
import Post from '../components/Post';

const blogSettings = moduleSettings('blog');

const Posts = ({ data, pageContext }) => {
  const posts = data.posts.edges;

  // Pagination
  const { currentPage, numPages } = pageContext;

  return (
    <Layout>
      <div className="color-mode-background-200">
        <div className="relative max-w-7xl mx-auto">
          {data?.author && (
            <ContentNarrow>
              <div className="text-center">
                {data?.author?.pageInfo?.name && (
                  <h1 className="text-primary">{data.author.pageInfo.name}</h1>
                )}
                {data?.author?.socialMediaHandles &&
                  data.author.socialMediaHandles && (
                    <div className="my-8 space-x-6 flex justify-center">
                      {data?.author?.socialMediaHandles?.facebook && (
                        <a
                          href={`https://facebook.com/${data.author.socialMediaHandles.facebook}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="color-mode-color hover:text-primary"
                        >
                          <span className="sr-only">Facebook</span>
                          <svg
                            className="h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      )}
                      {data?.author?.socialMediaHandles?.instagram && (
                        <a
                          href={`https://instagram.com/${data.author.socialMediaHandles.instagram}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="color-mode-color hover:text-primary"
                        >
                          <span className="sr-only">Instagram</span>
                          <svg
                            className="h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      )}
                      {data?.author?.socialMediaHandles?.twitter && (
                        <a
                          href={`https://twitter.com/${data.author.socialMediaHandles.twitter}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="color-mode-color hover:text-primary"
                        >
                          <span className="sr-only">Twitter</span>
                          <svg
                            className="h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
                          </svg>
                        </a>
                      )}
                      {data?.author?.socialMediaHandles?.linkedIn && (
                        <a
                          href={`https://linkedIn.com/in/${data.author.socialMediaHandles.linkedIn}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="color-mode-color hover:text-primary"
                        >
                          <span className="sr-only">LinkedIn</span>
                          <svg
                            className="h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6.46442 21.9543V8.60673H2.31236V21.9543h4.15206zM4.3663 6.82097c1.33932 0 2.41048-1.11535 2.41048-2.45467C6.77678 3.07116 5.70562 2 4.3663 2 3.07116 2 2 3.07116 2 4.3663c0 1.33932 1.07116 2.45467 2.3663 2.45467zM21.9985 21.9543v-7.3229c0-3.5715-.803-6.33855-4.9993-6.33855-2.0097 0-3.349 1.11535-3.928 2.14235h-.0458V8.60673H9.0547V21.9543h4.1521v-6.6067c0-1.7416.3123-3.3933 2.4531-3.3933 2.1438 0 2.188 1.9656 2.188 3.5273v6.4727H22h-.0015z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      )}
                      {data?.author?.socialMediaHandles?.youtube && (
                        <a
                          href={`https://youtube.com/channel/${data.author.socialMediaHandles.youtube}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="color-mode-color hover:text-primary"
                        >
                          <span className="sr-only">Youtube</span>
                          <svg
                            className="h-6 w-6"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fillRule="evenodd"
                              d="M11.9938 5s-6.25082.00001-7.81969.40767c-.84003.23471-1.53173.92642-1.76644 1.7788C2.00001 8.75534 2 12.0044 2 12.0044s.00001 3.2613.40767 4.8055c.23471.8523.91406 1.5317 1.76644 1.7664 1.58123.42 7.81969.42 7.81969.42s6.2632 0 7.8321-.4076c.8523-.2347 1.5317-.9017 1.754-1.7665C22 15.2657 22 12.0167 22 12.0167s.0123-3.26136-.4201-4.83023c-.2223-.85238-.9017-1.53172-1.754-1.75408C18.257 5.00002 11.9938 5 11.9938 5zm-1.9889 4.00254l5.2008 3.00186-5.2008 2.9894V9.00254z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </a>
                      )}
                    </div>
                  )}
                <BlockContent blocks={data.author._rawBio || []} />
              </div>
            </ContentNarrow>
          )}
          <Container className="justify-center grid">
            {data?.author?.pageInfo?.name && (
              <h2 className="text-xl">
                Posts by{' '}
                <span className="text-primary">
                  {data.author.pageInfo.name}
                </span>
                :
              </h2>
            )}
            <div
              className={`grid gap-8 lg:grid-cols-${
                data.settings && data.settings.columns
                  ? data.settings.columns
                  : '3'
              }`}
            >
              {posts.map((edge) => {
                const post = edge.node;
                return (
                  <Post className="max-w-3xl" key={post._id} post={post} />
                );
              })}
            </div>
          </Container>
          <Pagination
            currentPage={currentPage}
            numPages={numPages}
            baseUrl={`${blogSettings.baseName}/`}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Posts;

export const query = graphql`
  query($skip: Int!, $limit: Int!, $author: String!) {
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      limit: $limit
      skip: $skip
      filter: { author: { pageInfo: { slug: { current: { eq: $author } } } } }
    ) {
      edges {
        node {
          _id
          _rawExcerpt(resolveReferences: { maxDepth: 10 })
          _rawBody(resolveReferences: { maxDepth: 10 })
          author {
            _id
            pageInfo {
              name
              slug {
                current
              }
            }
            image {
              asset {
                _id
                fluid(maxWidth: 80) {
                  ...GatsbySanityImageFluid
                }
              }
              alt
              caption
            }
          }
          _rawAuthor
          categories {
            _id
            pageInfo {
              slug {
                current
              }
              name
            }
          }
          excerpt
          image {
            asset {
              _id
              fluid(maxWidth: 1600) {
                ...GatsbySanityImageFluid
              }
            }
            alt
            caption
          }
          pageInfo {
            slug {
              current
            }
            name
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          dateReadable: publishedAt(formatString: "MMMM DD, YYYY")
          date: publishedAt(formatString: "YYYY-MM-DD")
          seo {
            ...SeoFragment
          }
          subTitle
          readTime
          tags
        }
      }
    }
    author: sanityAuthor(pageInfo: { slug: { current: { eq: $author } } }) {
      pageInfo {
        name
      }
      socialMediaHandles {
        ...SocialMediaHandlesFragment
      }
      _rawBio(resolveReferences: { maxDepth: 10 })
    }
    settings: sanityBlogSettings {
      _rawContent(resolveReferences: { maxDepth: 10 })
      columns
    }
  }
`;
